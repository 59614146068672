import React from 'react';
import * as Scroll from 'react-scroll';
import {Events} from "react-scroll";
import './app.styles.scss';

import a from '../../assets/images/letter-a.svg';
import p from '../../assets/images/letter-p.svg';
import c from '../../assets/images/letter-c.svg';
import g from '../../assets/images/letter-g.svg';
import whiteLogo from '../../assets/images/logo-white.svg';
import {NavLink} from "react-router-dom";

import RegistrationView from "../registration/registration.component";

class App extends React.Component {
    state = {
        currentBlock: 0,
        activeHistory: 0,
        isRegistrationViewVisible: false
    }
    isBlockLoading = false;
    block = 0
    blocksIds = ['believe__block', 'about-the-company__block', 'despair__block', 'programms__block', 'go__block', 'travels__block', 'expirience__block', 'private-office__block', 'sky__block', 'contacts__block']
    menuItems = new Map<string, string>([
        ["О компании", "about-the-company__block"],
        ["Программы обслуживания", "programms__block"],
        ["Путешествия", "travels__block"],
        ["Private office", "private-office__block"],
        ["Контакты", "contacts__block"]
    ])
    histories = ['Руководитель одной восточноевропейской страны решил прокатиться по каналам в Санкт-Петербурге до\n' +
    '                        того, как подали горячее. К десерту лодка для Президента и катер сопровождения для охраны были\n' +
    '                        готовы. Северная Венеция прекрасна в июне.',
        'Хозяин квартиры на восточном побережье США, которую имел неосторожность арендовать наш клиент,\n' +
        '                        как\n' +
        '                        оказалось, не отличался порядочностью, и за день до заезда у нас возникли непредвиденные\n' +
        '                        сложности.\n' +
        '                        Однако грамотно составленное письмо от ведущего специалиста по уголовному праву штата Флорида на\n' +
        '                        5\n' +
        '                        страницах сотворило чудеса. И вправду говорят: американцы очень законопослушны.',
        'Что может быть лучше, чем корзина ярких пионов для прекрасной дамы к Рождеству? И вот 12 часов\n' +
        '                        спустя курьер с другого конца Европы несется в высокогорный Куршавель. Любимая будет довольна.',
        'Необычный подарок на день рождения? Мы попросили разных людей из Японии, Ливана, Норвегии и еще\n' +
        '                        пятнадцати стран записать персональное поздравление для юбиляра.']

    componentDidMount() {
        if (window.innerWidth > 1023) {
            window.addEventListener('DOMMouseScroll', preventDefault, {passive: false}); // older FF
            window.addEventListener('wheel', preventDefault, {passive: false}); // older FF
            window.addEventListener('touchmove', preventDefault, {passive: false}); // mobile

            this.scroll();

            document.addEventListener('wheel', (e) => {
                if (!this.isBlockLoading) {
                    this.hideHistories();

                    if (e.deltaY > 0 && this.block < this.blocksIds.length - 1) {
                        this.block += 1;
                        this.scroll();
                    } else if (e.deltaY < 0 && this.block > 0) {
                        this.block -= 1;
                        this.scroll();
                    }
                }
            });
        }
    }

    scroll() {
        this.isBlockLoading = true

        Events.scrollEvent.register('end', () => {
            this.setState({currentBlock: this.block})
            this.isBlockLoading = false;
            Events.scrollEvent.remove('end');
        });

        Scroll.scroller.scrollTo(this.blocksIds[this.block], {
            duration: 1000,
            delay: 100,
            smooth: 'easeInOutQuint'
        });
    }

    showHistories() {
        //document.body.className = 'modal';
        const headerElement = document.getElementById('header')
        if (headerElement) {
            headerElement.className = `${headerElement.className} header-slide`;
        }

        const historyElement = document.getElementById('history')
        if (historyElement) {
            historyElement.className = 'history-wrapper active';
        }
    }

    hideHistories() {
        this.blocksIds.forEach((blockId) => {
            const blockAsElement = document.getElementById(blockId);
            if (blockAsElement && blockAsElement.className.includes('slide')) {
                blockAsElement.className = blockAsElement.className.replace(' slide', '');
            }
        })
        document.body.className = '';

        const headerElement = document.getElementById('header')
        if (headerElement) {
            headerElement.className = headerElement.className.replace(' header-slide', '')
        }

        const historyElement = document.getElementById('history')
        if (historyElement) {
            historyElement.className = 'history-wrapper';
        }
    }

    render() {
        return <div className="App">
            {this.state.isRegistrationViewVisible && <RegistrationView onHide={() => {this.setState({isRegistrationViewVisible: false})}}/>}
            <div id="menu-shadow"></div>
            <div className="menu-wrapper" id='menu'>
                <div id="menu-close" onClick={() => {
                    document.getElementById('menu')!!.className = 'menu-wrapper'
                }}/>
                <ul>
                    <li><NavLink to={'/authorization'}>Личный кабинет</NavLink></li>
                    {Array.from(this.menuItems.keys()).map((title, i) => {
                        return <li key={i} onClick={() => {
                            if (!this.isBlockLoading) {
                                this.hideHistories();

                                const blockId = this.menuItems.get(title);
                                this.block = this.blocksIds.indexOf(blockId!!);
                                this.scroll();
                            }
                        }}>{title}</li>;
                    })}
                </ul>
            </div>
            <div className="history-wrapper" id='history'>
                <div className="history-title">Истории</div>
                <div id="histories">
                    {this.histories.map((history, i) => {
                        return <div key={i}
                                    className={this.state.activeHistory === i ? "history_item active" : "history_item"}>
                            {history}
                        </div>
                    })}
                </div>
                <div id="histories_switcher">
                    <div id="history_left" onClick={() => {
                        const newActiveHistory = this.state.activeHistory === 0 ? this.histories.length - 1 : this.state.activeHistory - 1
                        this.setState({activeHistory: newActiveHistory});
                    }}/>
                    <div id="history_right" onClick={() => {
                        this.setState({activeHistory: (this.state.activeHistory + 1) % this.histories.length});
                    }}/>
                </div>
                <div className="history-close__wrapper">
                    <div className="history-close" onClick={() => {
                        this.hideHistories();
                    }}>Закрыть
                    </div>
                </div>
            </div>
            <div
                className={document.getElementById(this.blocksIds[this.state.currentBlock])?.className.includes('white') ? "section-header white" : "section-header"}
                id='header'>
                <div className="section-container">
                    <div className="header-logo">
                        <div className="header-apps">
                        </div>
                        <div className="logo" style={{display: this.state.currentBlock === 0 ? 'none' : 'block'}} onClick={() => {
                           this.block = 0;
                           this.scroll();
                        }}>

                        </div>
                    </div>
                    <div className="header-phone">
                        <a href="tel:84959374860">+7 495 937 48 60</a>
                        <div className="menu-bottom" id='menu' onClick={() => {
                            document.getElementById('menu')!!.className = 'menu-wrapper active'
                        }}>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="section section-image" data-section-name="believe" id="believe__block" style={{
                backgroundImage: 'url(' + require('../../assets/images/img_screen1.jpg') + ')',
                height: (window.innerWidth > 1023) ? '100vh' : undefined
            }} onClick={() => {
                document.getElementById('menu')!!.className = 'menu-wrapper'
            }}>
                <div className="section-container">
                    <div className="content-block">
                        <div id="main-logo" className="text-center"><img alt='logo white' src={whiteLogo}/></div>
                        <div className="history-button__wrapper">
                            <div className="history-button" onClick={() => {
                                this.showHistories();
                                const blockAsElement = document.getElementById("believe__block");
                                blockAsElement!!.className = blockAsElement!!.className + ' slide';
                            }}>Истории
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="section section-content" data-section-name="about-the-company" id="about-the-company__block"
                 style={{
                     backgroundImage: `url(${a})`,
                     height: (window.innerWidth > 1023) ? '100vh' : undefined
                 }}
                 onClick={() => {
                     document.getElementById('menu')!!.className = 'menu-wrapper'
                 }}>
                <div className="section-container">
                    <div className="content-block one-column">
                        <div className="content-block__header">О компании</div>
                        <p>
                            В Apple Concierge мы занимаемся упорядочиванием хаоса. Мы производим свободное время и
                            свежие
                            эмоции, перерабатывая руду повседневности. Мы – Че Гевары lifestyle и Архимеды мира luxury.
                            На
                            каждое «нет» у нас два-три «да». Звонок другу? Это про нас.
                        </p>
                        <p>
                            SOS-хэлп? Выезжаем. Скрупулёзно, день за днем, год за годом, не привлекая лишнего внимания,
                            Apple Concierge делает жизнь наших дорогих клиентов лучше, проще, спокойнее. Некоторые
                            доверяют
                            нам более десяти лет. Вы с нами? Добро пожаловать.
                        </p>
                    </div>
                </div>
            </div>
            <div className="section section-image white" data-section-name="despair" id="despair__block" style={{
                backgroundImage: 'url(' + require('../../assets/images/img_screen2.jpg') + ')',
                height: (window.innerWidth > 1023) ? '100vh' : undefined
            }}  onClick={() => {
                document.getElementById('menu')!!.className = 'menu-wrapper'
            }}>
                <div className="section-container">
                    <div className="content-block">
                        <div className="section-image__header">
                            Впадать в отчаянье — <span>ПЛОХАЯ ПРИВЫЧКА</span>
                        </div>
                        <div className="history-button__wrapper">
                            <div className="history-button__wrapper">
                                <div className="history-button" onClick={() => {
                                    this.showHistories();
                                    const blockAsElement = document.getElementById("despair__block");
                                    blockAsElement!!.className = blockAsElement!!.className + ' slide';
                                }}>Истории
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="section section-content columns" data-section-name="programms" id="programms__block"
                 style={{
                     backgroundImage: `url(${p})`,
                     height: (window.innerWidth > 1023) ? '100vh' : undefined
                 }}
                 onClick={() => {
                     document.getElementById('menu')!!.className = 'menu-wrapper'
                 }}>
                <div className="section-container">
                    <div className="content-block__header">Программы обслуживания</div>
                    <div className="column left">
                        <div className="column-header">Индивидуальные</div>
                        Мы расскажем вам, что такое настоящая индивидуальная работа. Наши консьержи опаздывали на рейс и
                        не
                        улетали в отпуск, чтобы успеть доставить подарок ко дню рождения ребенка. Они отправляются в
                        ресторан, чтобы лично выбрать для вас лучшее место и не забыть отблагодарить метрдотеля. У дочки
                        в
                        Женеве закончились деньги на карточке? Пополним, не беспокойтесь, в конце месяца оплатите с
                        общим
                        счетом. Это hand-made, господа.
                    </div>
                    <div className="column right">
                        <div className="column-header">Банкам</div>
                        Знаете, что такое соблюдение условий Service Level Agreement? Это успеть забронировать ресторан,
                        пока горит спичка. На наших тренингах сотрудникам рассказывают все нюансы работы кредитных карт,
                        они
                        наизусть помнят «бины» банков, с которыми мы сотрудничаем. Проблемы с priority pass или
                        юридической
                        поддержкой для держателей премиального «пластика»? У нас есть интересные решения. Потому что
                        ваши
                        клиенты – они ведь наши клиенты.
                    </div>
                </div>
            </div>
            <div className="section section-image" data-section-name="go" id="go__block" style={{
                backgroundImage: 'url(' + require('../../assets/images/img_screen3.jpg') + ')',
                height: (window.innerWidth > 1023) ? '100vh' : undefined
            }}  onClick={() => {
                document.getElementById('menu')!!.className = 'menu-wrapper'
            }}>
                <div className="section-container">
                    <div className="content-block">
                        <div className="section-image__header">
                            <span>ПОЕХАЛИ!</span>
                        </div>
                        <div className="history-button__wrapper">
                            <div className="history-button" onClick={() => {
                                this.showHistories();
                                const blockAsElement = document.getElementById("go__block");
                                blockAsElement!!.className = blockAsElement!!.className + ' slide';
                            }}>Истории
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="section section-content" data-section-name="travels" id="travels__block" style={{
                backgroundImage: `url(${c})`,
                height: (window.innerWidth > 1023) ? '100vh' : undefined
            }} onClick={() => {
                document.getElementById('menu')!!.className = 'menu-wrapper'
            }}>
                <div className="section-container">
                    <div className="content-block one-column">
                        <div className="content-block__header">Путешествия</div>
                        <p>
                            Что требуется для того, чтобы организовать хорошую поездку? Глубочайшее знание всех нюансов
                            отдыха в Бутане и Кито, Вистлере и Киото. Дружба с отельерами со всего мира. Справочник
                            партнеров на тысячу имен. Собственная билетная касса.
                        </p>
                        <p>
                            Доступ к закрытым системам бронирования. Десятки сотрудников, круглосуточно отслеживающие
                            перемещение клиентов и готовых прийти на помощь. Вроде, ничего про себя не забыли. Поехали!
                        </p>
                    </div>
                </div>
            </div>
            <div className="section section-image white" data-section-name="expirience" id="expirience__block" style={{
                backgroundImage: 'url(' + require('../../assets/images/img_screen4.jpg') + ')',
                height: (window.innerWidth > 1023) ? '100vh' : undefined
            }}  onClick={() => {
                document.getElementById('menu')!!.className = 'menu-wrapper'
            }}>
                <div className="section-container">
                    <div className="content-block">
                        <div className="section-image__header">
                            Опыт измеряется <br/> не в годах, <span> А В СОБЫТИЯХ</span>
                        </div>
                        <div className="history-button__wrapper">
                            <div className="history-button" onClick={() => {
                                this.showHistories();
                                const blockAsElement = document.getElementById("expirience__block");
                                blockAsElement!!.className = blockAsElement!!.className + ' slide';
                            }}>Истории
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="section section-content" data-section-name="private-office" id="private-office__block"
                 style={{
                     backgroundImage: `url(${g})`,
                     height: (window.innerWidth > 1023) ? '100vh' : undefined
                 }}
                 onClick={() => {
                     document.getElementById('menu')!!.className = 'menu-wrapper'
                 }}>
                <div className="section-container">
                    <div className="content-block one-column">
                        <div className="content-block__header">Private office</div>
                        <p>
                            Латынь из моды вышла ныне, но nil satis nisi optimum — только лучшее достаточно хорошо. Нам
                            доверяют не только свои повседневные заботы, вроде записи в салон красоты, или организации
                            деловой поездки.
                        </p>
                        <p>
                            Для очень узкого круга клиентов, которых всегда не больше десяти, мы разработали специальное
                            предложение, гарантирующее высочайший уровень решения самых насущных и зачастую деликатных
                            задач.
                        </p>
                    </div>
                </div>
            </div>
            <div className="section section-image" data-section-name="sky" id="sky__block" style={{
                backgroundImage: 'url(' + require('../../assets/images/img_screen5.jpg') + ')',
                height: (window.innerWidth > 1023) ? '100vh' : undefined
            }} onClick={() => {
                document.getElementById('menu')!!.className = 'menu-wrapper'
            }}>
                <div className="section-container">
                    <div className="content-block">
                        <div className="section-image__header">
                            Зачем земля, <span> КОГДА ЕСТЬ НЕБО?</span>
                        </div>
                        <div className="history-button__wrapper">
                            <div className="history-button" onClick={() => {
                                this.showHistories();
                                const blockAsElement = document.getElementById("sky__block");
                                blockAsElement!!.className = blockAsElement!!.className + ' slide';
                            }}>Истории
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="section section-content" data-section-name="contacts" id="contacts__block" style={{
                backgroundImage: 'url(' + require('../../assets/images/letters-footer__bg.png') + ')',
                height: (window.innerWidth > 1023) ? '100vh' : undefined
            }} onClick={() => {
                document.getElementById('menu')!!.className = 'menu-wrapper'
            }}>
                <div className="section-container">
                    <div className="content-block">
                        <div className="content-block__header">Контакты</div>
                        <p>
                            Авиационная 77/5, 123182, Москва
                        </p>
                        <p>
                            info@apcg.com
                        </p>
                        <p>
                            +7 495 937 48 60
                        </p>
                        <div className='black-button' onClick={() => {
                            this.setState({isRegistrationViewVisible: true});
                        }}>Стать клиентом</div>
                        <div className="contacts-icons">
                        </div>
                    </div>
                    <div className="footer-menu">
                        <ul>
                            <li><a href="/files/oferta.pdf" target="_blank" rel="noreferrer">Договор
                                оферты</a></li>
                        </ul>
                        <ul>
                            <li><a href="/files/payment_info.pdf" target="_blank" rel="noreferrer">
                                Платежная информация
                            </a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>;
    }
}

function preventDefault(e: any) {
    e.preventDefault();
}

export default App;
